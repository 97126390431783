export default {
  teamMembers: [
    {
      name: "Thiago Avelino",
      title: "Co-founder & CEO",
      avatar: "Thiago",
    },
    {
      name: "Jones R. Avelino Jr",
      title: "Co-founder & CTO",
      avatar: "Jones",
    },
    {
      name: "Joice Rodrigues",
      title: "Time Comercial",
      avatar: "Joice",
    },
    {
      name: "Priscila Samara",
      title: "Time Comercial",
      avatar: "Priscila",
    },
    {
      name: "Morgana Vettorazzi",
      title: "Time de Produto",
      avatar: "Morgana",
    },
    {
      name: "Gabriel Viggiano",
      title: "Time de Produto",
      avatar: "Gabriel",
    },
    {
      name: "Caio Occhi",
      title: "Time de Produto",
      avatar: "Caio",
    },
    {
      name: "Brayan Bautz",
      title: "Time de Produto",
      avatar: "Brayan",
    },
    {
      name: "Bruna Dela Costa",
      title: "Time de Operações",
      avatar: "Bruna",
    },
    {
      name: "Emanuelle Dal Moro",
      title: "Time de Operações",
      avatar: "Emanuelle",
    },
    {
      name: "Murilo de Lima Coelho",
      title: "Time de CS/CX",
      avatar: "Murilo"
    },
    {
      name: "Mayara Carla",
      title: "Time de CS/CX",
      avatar: "Mayara",
    },
    {
      name: "Gabriel Graciano",
      title: "Time de CS/CX",
      avatar: "GabrielGraciano",
    },
    {
      name: "Luciana Vieira",
      title: "Time de Marketing",
      avatar: "Luciana"
    },
    {
      name: "Igor Zovedi",
      title: "Time de Marketing",
      avatar: "Igor"
    }
    
  ],

  contactInfo: {
    company: {
      companyName:
        "SafetyTec Tecnologia e Inovação<br />em Segurança do Trabalho LTDA.",
      cnpj: "14.957.619/0001-01",
      address:
        "Av. Comendador Franco, 1341<br />Jardim Botânico, Curitiba, PR, CEP: 81920-380",
      phone: "+55 (41) 99835-3630",
      email: "suporte@safetytec.com.br",
    },
  },
};
